<template>
  <div class="border-b border-gray-200 sticky top-16 z-10 bg-white">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <nav class="-mb-px block md:flex justify-between md:space-x-4">
        <div class="flex space-x-4 md:space-x-8 overflow-x-auto" aria-label="Tabs">
          <a v-for="tab in tabs" @click="currentTab = tab.name" :key="tab.name" href="#" :class="[tab.name === currentTab ? 'border-ifgreen text-ifgreen' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200', 'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm']">
            {{ tab.label }}
            <span :class="[tab.name === currentTab ? 'bg-ifgreen text-white' : 'bg-gray-100 text-gray-900', 'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block']">{{ tab.count }}</span>
          </a>
        </div>
        <div class="py-2">
          <button @click="openFilters" type="button" class="hidden md:flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none">
            <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            <span class="ml-2">{{ $t('search') }}</span>
          </button>
        </div> 
      </nav>
    </div>
  </div>
  <Filters ref="filtersPanel" />
  <div 
    v-for="tab in tabs"
    v-show="currentTab === tab.name"
    :key="tab.name"
  >
    <AdminPagination
      entity="installations"
      :storeKey="tab.name"
      :status="tab.name"
    >
      <AdminInstallationCard
        v-for="inst in installations[tab.name].data"
        :key="inst.id"
        :installation="inst"
        @update-notes="updateNotes"
        @update-reason="updateReason"
        @resolve-installation="openConfirmModal"
        @share-installation="openSharePanel"
        @edit-device="openDeviceEditPanel"
        @edit-installation="openInstallationEditPanel"
      />
    </AdminPagination>
  </div>
  <Modal
    ref="modal"
    :title="modalTitle"
    :text="modalText"
    :cancel-button-label="$t('cancel')"
  >
    <template v-if="modalAction === 'accept' || modalAction === 'reject'" #textarea>
      <div class="w-full mt-5">
        <label for="rejectionReason" class="block text-sm font-medium text-gray-700">
          {{$t("additionalNotes")}} <span class="text-gray-500">({{$t("adminClientAdditionalNotes")}} )</span>
        </label>
        <div class="mt-1 relative">
          <textarea
            id="rejectionReason"
            name="rejectionReason"
            v-model="rejectionReason"
            class="appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-ifgreen focus:border-ifgreen sm:text-sm"
          />
        </div>
      </div>
    </template>
    <template v-if="modalAction === 'delete'" #checkbox>
      <div class="w-full mt-5">
        <div class="flex justify-center">
          <input
            id="deleteDevice"
            name="deleteDevice"
            type="checkbox"
            v-model="deleteWithDevice"
            class="rounded text-ifgreen focus:ring-ifgreen mr-2"
          />
          <label for="deleteDevice" class="block text-sm font-medium text-gray-700">
            {{$t("deleteWithDevice")}}
          </label>
        </div>
      </div>
    </template>
    <button v-if="modalAction === 'accept'" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-ifgreen text-base font-medium text-white hover:bg-ifgreen-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen sm:col-start-2 sm:text-sm" @click="resolveInstallation('accepted')">
      {{$t("accept")}}
    </button>
    <button v-if="modalAction === 'reject'" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:col-start-2 sm:text-sm" @click="resolveInstallation('rejected')">
      {{$t("reject")}}
    </button>
    <button v-if="modalAction === 'delete'" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:col-start-2 sm:text-sm" @click="deleteInstallation(actionInstallation)">
      {{$t("delete")}}
    </button>
    <button v-if="modalAction === 'revert'" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-ifgreen text-base font-medium text-white hover:bg-ifgreen-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen sm:col-start-2 sm:text-sm" @click="resolveInstallation('pending')">
      {{$t("restore")}}
    </button>
  </Modal>

  <ShareInstallation ref="shareInstallationPanel" />
  <EditDevice 
    ref="editDevicePanel"
    :device="editDevice"
    :tabToRefresh="currentTab"
  />
  <EditInstallation
    ref="editInstallationPanel"
    :installation="actionInstallation"
    method="patch"
  />
</template>

<script>
//import { ArrowLeftIcon, CheckCircleIcon } from '@heroicons/vue/24/outline'
import AdminPagination from '@/components/admin/AdminPagination.vue'
import AdminInstallationCard from '@/components/admin/AdminInstallationCard.vue'
import SearchBar from '@/components/inputs/SearchBar.vue';
import Modal from '@/components/layout/Modal.vue';
import Filters from '@/components/layout/Filters.vue';
import ShareInstallation from '@/components/installations/ShareInstallation.vue';
import EditDevice from '../../components/admin/EditDevice.vue';
import EditInstallation from '@/components/installations/EditInstallation.vue';
import generateWarrantyPdf from '@/assets/js/generateWarrantyPdf.js'
import { uploadFile, getDeviceSerialNumber, s3url, attachTemplate, serializeParams, resolveWarrantyTemplate, resolveWarrantyAttachments } from '@/assets/js/helpers.js'
import { mapState } from 'vuex'
import { api, patchHeaders } from "@/assets/js/api-client.js";
import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline'
// const s3url = "https://izzifast-installer-app.s3.eu-west-2.amazonaws.com/";

export default {
  name: "AdminInstallations",
  components: {
    AdminPagination,
    AdminInstallationCard,
    Modal,
    SearchBar,
    Filters,
    ShareInstallation,
    EditDevice,
    EditInstallation,
    MagnifyingGlassIcon
  },
  data () {
    return {
      currentTab: 'pending',
      modalTitle: '',
      modalText: '',
      modalAction: '',
      rejectionReason: null,
      actionInstallation: null,
      actionDevice: null,
      deleteWithDevice: false,
      warrantyPdf: null,
      warrantyAttachments: [],
      editDevice: null
    }
  },
  computed: {
    ...mapState(['organisation','role','deviceTemplates','installations']),
    idFields () {
      const idFields = []
      this.deviceTemplates.forEach(template => {
        template.versions.forEach(version => {
          version.parametersSchema.forEach(field => {
            if (field.barcodeScanner) idFields.push({name: field.name})
          })
        })
      })
      return idFields
    },
    itemIndex(currentPage, constant){
      return currentPage * this.numbersOfItemsOnPage + constant
    },  
    tabs () {
      return [
        { label: `${this.$t("pending")}`, name: 'pending', count: this.installations.pending.pagination.total },
        { label: `${this.$t("accepted")}`, name: 'accepted', count: this.installations.accepted.pagination.total },
        { label: `${this.$t("rejected")}`, name: 'rejected', count: this.installations.rejected.pagination.total },
        { label: `${this.$t("installation.noWarranty")}`, name: 'no_warranty', count: this.installations.no_warranty.pagination.total },
      ]
    }
  },
  methods: {
    openFilters () {
      this.$refs.filtersPanel.show()
    },
    updateNotes (e) {
      const data = {
        id: e.id,
        adminNotes: e.notes
      }
      this.updateInstallation (data, [this.currentTab])
    },
    updateReason (e) {
      const data = {
        id: e.id,
        rejectionReason: e.reason
      }
      this.updateInstallation (data, [this.currentTab])
    },
    openConfirmModal(e) {
      this.modalTitle = e.title
      this.modalText = e.text
      this.modalAction = e.action
      this.actionInstallation = e.installation
      this.$refs.modal.show()
    },
    openDeviceEditPanel (device) {
      this.editDevice = device
      this.$nextTick(() => {
        this.$refs.editDevicePanel.show()
      })
    },
    openInstallationEditPanel (installation) {
      this.actionInstallation = installation
      this.$nextTick(() => {
        this.$refs.editInstallationPanel.show()
      })
    },
    async resolveInstallation (status) {
      this.$refs.modal.close()
      setTimeout(() => {
        this.$store.dispatch('setLoading', true)
      }, 10)

      const device = serializeParams(attachTemplate(this.actionInstallation.customDevice))
      const {installationDate, installer, customerEmail, receivers, language} = this.actionInstallation

      const emails = [ installer.email ]
      if (receivers) receivers.forEach(receiver => {emails.push(receiver.email)})
      if (customerEmail) emails.push(customerEmail)

      if (status === 'accepted') {

        const { warrantySchema } = device.schemaVersion
        if (warrantySchema) {
          if (warrantySchema[language].customTemplate.enabled) {
            this.warrantyPdf = await resolveWarrantyTemplate(warrantySchema[language].customTemplate, this.actionInstallation)
          }
          this.warrantyAttachments = resolveWarrantyAttachments(warrantySchema[language].attachments, this.actionInstallation)
        } else {
          this.warrantyPdf = await generateWarrantyPdf(this.actionInstallation)
        }

        const result = await api.post('/get-s3-urls', [{filename: `${device.id}-warranty-card.pdf`, filetype: 'application/pdf'}])
        
        await uploadFile(result.data.urls[0], this.warrantyPdf, 'application/pdf')
        const warrantyLink = result.data.urls[0].split('?')[0]
        const data = {
          id: this.actionInstallation.id,
          status: status,
          rejectionReason: this.rejectionReason ? this.rejectionReason : null
        }

        this.updateInstallation (data, ['pending', 'accepted'])

        const warrantyData = {
          organisation: this.organisation,
          device_id: getDeviceSerialNumber(device),
          warranty_card: warrantyLink,
          warranty_attachments: this.warrantyAttachments,
          installation_card: `${s3url}${device.id}-installation-card.pdf`,
          installation_date: new Date(installationDate).toLocaleDateString('pl-PL'),
          email: emails,
          note: this.rejectionReason ? this.rejectionReason : null,
          language
        }

        this.sendWarranty(warrantyData)
      }
      else if (status === 'rejected') {
        const data = {
          id: this.actionInstallation.id,
          status: status
        }

        if (this.rejectionReason) data['rejectionReason'] = this.rejectionReason

        this.updateInstallation (data, ['pending', 'rejected'])

        const emailData = {
          organisation: this.organisation,
          device_id: getDeviceSerialNumber(device),
          installation_card: `${s3url}${device.id}-installation-card.pdf`,
          installation_date: new Date(installationDate).toLocaleDateString('pl-PL'),
          email: emails,
          language
        }

        if (this.rejectionReason) emailData['note'] = this.rejectionReason

        this.sendRejection(emailData)
      }
      else {
        const data = {
          id: this.actionInstallation.id,
          status: status
        }

        this.updateInstallation (data , ['pending', 'rejected'])
      }
    },
    async updateInstallation (installation, statusesToRefresh) {
      try {
        const result = await api.patch(`/installations/${installation.id}`, installation, patchHeaders)
        console.log(result)
        for (const status of statusesToRefresh) {
          await this.$store.dispatch('getCollection', {entity: 'installations', page: this.installations[status].pagination.currentPage, status})
        }    
      } catch (error) {
        console.log(error)
      }
      this.$store.dispatch('setLoading', false)
    },
    async deleteInstallation (installation) {
      if (this.deleteWithDevice) {
        if (!confirm(this.$t('installation.deleteDeviceConfirmation'))) return
      }
      this.$refs.modal.close()
      this.$store.dispatch('setLoading', true)

      try {
        const result = await api.delete(`/installations/${installation.id}`)
        console.log(result)
        if (this.deleteWithDevice) {
          const deviceId = installation.customDevice.id
          try {
            const result = await api.delete(`/custom_devices/${deviceId}`)
            console.log(result)
          } catch (error) {
            console.log(error)
          }
        }
        await this.$store.dispatch('getCollection', {entity: 'installations', page: this.installations['accepted'].pagination.currentPage, status: 'accepted'})
        await this.$store.dispatch('getCollection', {entity: 'installations', page: this.installations['rejected'].pagination.currentPage, status: 'rejected'})
      } catch (error) {
        console.log(error)
      }
      this.$store.dispatch('setLoading', false)
    },
    openSharePanel (installation) {
      this.$store.dispatch('saveCurrentInstallation', installation)
      this.$refs.shareInstallationPanel.show()
    },
    sendWarranty (data) {
      api.post('/send-email', {action: 'warranty', data}).then(result => {
        console.log(result)
        this.$notify({
          title: data.email,
          text: this.$t('installation.emailSentConfirmation'),
          type: 'success',
          duration: 5000
        })
      }).catch((error) => {
        console.log(error)
      })
    },
    sendRejection (data) {
      api.post('/send-email', {action: 'rejectWarranty', data}).then(result => {
        console.log(result)
        this.$notify({
          title: data.email,
          text: this.$t('installation.rejectionSentConfirmation'),
          type: 'success',
          duration: 5000
        })
      }).catch((error) => {
        console.log(error)
      })
    }
  },
  watch: {
    async organisation () {
      this.$store.dispatch('setLoading', true)
      await this.$store.dispatch('getDeviceTemplates')
      await this.$store.dispatch('getCollection', {entity: 'installations', key: 'pending', status: "pending", page: 1})
      await this.$store.dispatch('getCollection', {entity: 'installations', key: 'accepted', status: "accepted", page: 1})
      await this.$store.dispatch('getCollection', {entity: 'installations', key: 'rejected', status: "rejected", page: 1})
      this.$store.dispatch('setLoading', false)
    }
  }
};
</script>